<template>
  <main class="home" v-if="finalOutput">
    <div class="projects">
      <div v-for="(letter, key) in finalOutput" :key="key.id" class="key">
        <div class="key-title">
          {{ letter.key }}
        </div>
        <template v-for="project in letter.projects">
          <div :key="project.uid" class="project">
            <span class="title">{{ project.title }}</span>
            <span v-if="project.description">, {{ project.description }}</span>
            <span v-if="project.year">, {{ project.year }}</span>
            <div class="project-files">
              <template v-for="file in project.files">
                <div
                  v-if="file.extension === 'txt' && $store.state.types.texts"
                  ref="file"
                  :key="file.id"
                  class="project-file"
                  @click="openGallery(project.uid, 0)"
                >
                  {{ project.index }}.0<sup class="filetype">TXT</sup>
                  <div class="file-thumb preview">Text</div>
                </div>
                <div
                  v-if="file.extension === 'jpg' && $store.state.types.images"
                  ref="file"
                  :key="file.id"
                  class="project-file"
                  @click="openGallery(project.uid, file.index)"
                >
                  {{ project.index }}.{{ file.index
                  }}<sup class="filetype">{{ file.extension }}</sup>
                  <div class="file-thumb">
                    <img
                      v-lazy="file.thumb"
                      :data-srcset="file.srcset"
                      alt="project.title"
                    />
                  </div>
                </div>
                <div
                  v-if="
                    file.extension === 'mp4' ||
                    (file.extension === 'mov' && $store.state.types.video)
                  "
                  ref="file"
                  :key="file.id"
                  class="project-file"
                  @click="openGallery(project.uid, file.index)"
                >
                  {{ project.index }}.{{ file.index
                  }}<sup class="filetype">{{ file.extension }}</sup>
                  <div class="file-thumb preview">
                    Video<br />
                    {{ file.caption }}
                  </div>
                </div>
                <div
                  v-if="file.extension === 'mp3' && $store.state.types.audio"
                  ref="file"
                  :key="file.id"
                  class="project-file"
                  @click="openGallery(project.uid, file.index)"
                >
                  {{ project.index }}.{{ file.index
                  }}<sup class="filetype">{{ file.extension }}</sup>
                  <div class="file-thumb preview">
                    Audio<br />
                    {{ file.caption }}
                  </div>
                </div>
                <div
                  v-if="
                    file.extension === 'pdf' && $store.state.types.documents
                  "
                  ref="file"
                  :key="file.id"
                  class="project-file"
                  @click="openGallery(project.uid, file.index)"
                >
                  {{ project.index }}.{{ file.index
                  }}<sup class="filetype">{{ file.extension }}</sup>
                  <div class="file-thumb preview">
                    Document<br />
                    {{ file.caption }}
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>
    <transition :name="slide" mode="out-in">
      <router-view :key="$route.path" />
    </transition>
  </main>
  <main v-else>Loading...</main>
</template>

<script>
import { forEach, filter, pickBy, indexOf, map } from "lodash";
export default {
  data() {
    return {
      slideId: 0,
      galleryOpen: false,
      startCount: 0,
      slide: "slide",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === null) {
        vm.startCount = 0;
      } else {
        vm.startCount = 1;
      }
    });
  },
  computed: {
    output() {
      return this.$store.state.year;
    },
    categorised() {
      let allTags = this.$store.state.categories;
      let falseCategories = pickBy(allTags, function (value, key) {
        if (!value) {
          return key;
        }
      });
      let stringCategories = map(falseCategories, function (value, key) {
        return key;
      });
      let newList = [];
      forEach(this.output, function (key) {
        let projects = key.projects;
        let newProjects = filter(projects, function (project) {
          return indexOf(stringCategories, project.categories) === -1;
        });
        if (newProjects.length > 0) {
          let object = {
            key: key.key,
            projects: newProjects,
          };
          newList.push(object);
        }
      });
      return newList;
    },
    finalOutput() {
      let totalCount = this.startCount;
      forEach(this.categorised, function (key) {
        forEach(key.projects, function (project) {
          forEach(project.files, function (file) {
            file["num"] = totalCount;
            totalCount++;
          });
        });
      });
      return this.categorised;
    },
  },
  methods: {
    openGallery(uid, slideId) {
      this.$router.push({
        name: "project",
        params: { id: uid },
        hash: "#" + slideId,
      });
      this.$store.state.settings = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s;
}
.slide-enter,
.slide-leave-to {
  transform: translate3d(0, 100vh, 0);
  // opacity:0;
}
.projects {
  width: 100%;
  box-sizing: border-box;
  min-height: 50%;
  column-count: 2;
  column-gap: calc(4 * var(--lh));
  @media only screen and (max-width: 768px) {
    column-count: 1;
  }
  .key {
    break-inside: avoid;
    .key-title {
      font-feature-settings: "c2sc", "smcp";
      font-variant: all-small-caps;
      text-transform: lowercase;
      // text-transform: uppercase;
      margin: var(--lh) 0;
    }
    &:first-of-type {
      .key-title {
        margin-top: 0;
      }
    }
    .project {
      margin-bottom: var(--lh);
      .project-files {
        padding-left: calc(2 * var(--lh));
        .project-file {
          display: inline-block;
          width: calc(100% / 4);
          transition: color 0.5s;
          position: relative;
          user-select: none;
          &:hover {
            color: #aaa;
            cursor: pointer;
            .file-thumb {
              display: block;
              &.preview {
                display: flex;
              }
            }
          }
          .file-thumb {
            display: none;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            mix-blend-mode: multiply;
            pointer-events: none;
            text-align: center;
            color: black;
            &.preview {
              align-items: center;
              justify-content: center;
              height: calc(30vw * 0.75);
              width: 30vw;
              background: rgba(0, 0, 0, 0.1);
              color: rgba(0, 0, 0, 0.8);
            }
            img {
              height: 30vw;
              width: 30vw;
              object-fit: contain;
              filter: grayscale(100%);
              @media only screen and (max-width: 1024px) {
                width: 45vw;
                height: 45vw;
              }
            }
          }
          .filetype {
            vertical-align: super;
            font-size: calc(var(--fs) / 1.6);
            font-feature-settings: "c2sc", "smcp";
            font-variant: all-small-caps;
            padding-left: 0.25vw;
            letter-spacing: 0.05vw;
          }
        }
      }
    }
  }
}
</style>
